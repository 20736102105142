import * as React from 'react'

import Layout from 'layouts/main'
import StudentSubscriptionLayout from '../components/studentSubscription/studentSubscriptionLayout'

const SubscriptionPage = () => (
  <Layout title={'Subscription'}>
    <StudentSubscriptionLayout />
  </Layout>
)

export default SubscriptionPage
